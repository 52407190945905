@import '../theme-bootstrap';

.product-gallery {
  text-align: center;
  @include breakpoint($medium-up) {
    padding: 0 10px;
  }
  @include breakpoint($large-up) {
    padding-#{$ldirection}: 10px;
    padding-#{$rdirection}: 0;
  }
  &__main {
    @include swap_direction(padding, 0 20px 0 10px);
    width: 100%;
    height: auto;
    margin: 0 0 5px;
    position: relative;
    @include breakpoint($medium-up) {
      padding: 0;
      height: 415px;
      max-height: 390px;
    }
    @include breakpoint($large-up) {
      max-height: 415px;
    }
  }
  &__photo--large {
    display: none;
    max-width: 415px;
    height: auto;
    &.active {
      display: block;
      margin: auto;
    }
  }
  &__photo--thumb {
    cursor: pointer;
  }
  &__thumbs {
    @include pie-clearfix;
    padding: 5px 0 0;
    margin: 0;
    height: 80px;
    display: none;
    text-align: center;
    @include breakpoint($medium-up) {
      display: block;
    }
    &--wrapper {
      position: relative;
    }
    &--badge,
    &--badgeQS {
      position: absolute;
      top: 0;
      #{$ldirection}: 0;
      padding: 10px;
      color: $color-dark-pink;
      font-weight: 700;
      text-align: $ldirection;
    }
    &--badge {
      @include breakpoint($medium-up) {
        padding: 10px 26px;
      }
      @include breakpoint($large-up) {
        padding: 10px 64px;
      }
    }
  }
  &__thumb {
    display: inline-block;
    height: auto;
    margin-#{$ldirection}: 10px;
    vertical-align: top;
    overflow: hidden;
    border: 1px solid $color-light-gray;
    a {
      display: block;
    }
    &.active {
      border: 1px solid $color-border-gray;
    }
    &:first-child {
      margin-#{$ldirection}: 0;
    }
  }
  &__shade {
    text-align: center;
    position: relative;
    margin-bottom: 15px;
    @include breakpoint($small-down) {
      margin-bottom: 2px;
    }
  }
  &__shade-name {
    @include h8;
  }
  &__shade-description {
    @include h9;
    line-height: 21px;
  }

  // SPP
  &--trigger {
    display: none;
  }
  .video-play {
    &__inner {
      margin: 0 auto;
      max-width: 415px;
    }
    &__container {
      height: auto;
      position: absolute;
      top: 50%;
      @include transform(translateY(-50%));
      width: 100%;
    }
  }
  &__zoom-trigger {
    color: $color-gray;
    font-size: 21px;
    text-decoration: none;
    position: absolute;
    #{$rdirection}: 0;
    top: 1em;
    &:hover {
      text-decoration: none;
    }
    span:hover {
      cursor: pointer;
    }
  }
  &__zoom-container {
    position: absolute;
    #{$ldirection}: 50%;
    top: 0;
    width: 474px;
    height: 474px;
    z-index: 999;
  }
}
